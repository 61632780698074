/* You can add global styles to this file, and also import other style files */

// @import "assets/sass/main.scss";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";
@import '~@ctrl/ngx-emoji-mart/picker';


@import "./assets/css/row.css";
@import "./assets/css/alignment.css";
@import "./assets/css/margin-padding.css";

html,
body {
    height: 100%;
    overflow: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.space-10 {
    height: 10em;
}